export default [
  {
    path: '/position',
    name: 'position',
    component: () => import('@/views/pages/master-data/position/List.vue'),
    meta: {
      name: 'Posisi',
      resource: 'Management',
      action: 'manage',
    },
  },
]
