export default [
  {
    path: '/risk-review',
    name: 'risk-review',
    component: () => import('@/views/pages/risk-review/Index.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'risk-review',
      action: 'manage',
    },
  },
]
