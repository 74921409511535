import businessType from './business-type'
import businessCategory from './business-category'
import partner from './partner'
import information from './information'
import verification from './verification'

export default [
  // ...partner, ...businessCategory, ...businessType,
  ...information, ...verification,
]
