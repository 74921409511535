import operationalOffice from './operational-office'
import office from './working-space'
import division from './division'
import position from './position'

export default [
  ...operationalOffice,
  ...office,
  // ...position,
  // ...division,
]
