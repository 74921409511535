export default [
  {
    path: '/skill',
    name: 'skill',
    component: () => import('@/views/pages/komtim/training/skills/Index.vue'),
    meta: {
      name: 'Skill',
      routeCreate: 'skill-add',
      routeEdit: 'skill-edit',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
  {
    path: '/skill/add',
    name: 'skill-add',
    component: () => import('@/views/pages/komtim/training/skills/Add.vue'),
    meta: {
      name: 'Skill',
      navActiveLink: 'skill',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
  {
    path: '/skill/:id',
    name: 'skill-edit',
    component: () => import('@/views/pages/komtim/training/skills/Add.vue'),
    meta: {
      name: 'Skill',
      navActiveLink: 'skill',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
]
