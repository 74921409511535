export default [
  {
    path: '/verification-ktp',
    name: 'verification-ktp',
    component: () => import('@/views/pages/partner/verification/List.vue'),
    meta: {
      resource: 'Partner',
      navActiveLink: 'verification-ktp',
      action: 'read',
    },
  },
  {
    path: '/verification-ktp/history/:id',
    name: 'verification-ktp',
    component: () => import('@/views/pages/partner/verification/ListHistory.vue'),
    meta: {
      resource: 'Partner',
      navActiveLink: 'verification-ktp',
      action: 'read',
    },
  },
  {
    path: '/verification-ktp/:id/file/:id_partner',
    name: 'verification-ktp',
    component: () => import('@/views/pages/partner/verification/File.vue'),
    meta: {
      resource: 'Partner',
      navActiveLink: 'verification-ktp',
      action: 'read',
    },
  },
  {
    path: '/verification-ktp/history/:id/:id_history/:id_partner',
    name: 'verification-ktp',
    component: () => import('@/views/pages/partner/verification/FileDetail.vue'),
    meta: {
      resource: 'Partner',
      navActiveLink: 'verification-ktp',
      action: 'read',
    },
  },
]
