export default [
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/pages/management-admin/user/List.vue'),
    meta: {
      name: 'Role Access',
      routeCreate: 'role-access-create',
      routeEdit: 'role-access-edit',
      resource: 'Kelola User',
      action: 'manage',
    },
  },
]
