export default [
  {
    path: '/rekonsiliasi',
    name: 'rekonsiliasi',
    component: () => import('@/views/pages/rekonsiliasi/List.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'rekonsiliasi',
      action: 'manage',
    },
  },
  {
    path: '/rekonsiliasi/pengajuan',
    name: 'rekonsiliasi-upload',
    component: () => import('@/views/pages/rekonsiliasi/Upload.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'rekonsiliasi',
      action: 'manage',
    },
  },
]
