const pageName = { singular: 'Divisi', plural: 'Divisi' }

export default [
  {
    path: '/division',
    name: 'division',
    component: () => import('@/views/pages/master-data/division/List.vue'),
    meta: {
      name: 'Divisi',
      resource: 'Management',
      action: 'manage',
    },
  },
]
