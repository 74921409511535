export default [
  {
    path: '/assignment/partners',
    name: 'assignment-partners',
    component: () => import('@/views/pages/assignment/partner/List.vue'),
    meta: {
      name: 'Assign Partner',
      routeCreate: 'assignment-partners-create',
      routeShow: 'assignment-partners-show',
      resource: 'Komtim - Assignment',
      action: 'manage',
    },
  },
  {
    path: '/assignment/partners/create',
    name: 'assignment-partners-create',
    component: () => import('@/views/pages/assignment/partner/Add.vue'),
    meta: {
      name: 'Assign Partner',
      navActiveLink: 'assignment-partners',
      resource: 'Komtim - Assignment',
      action: 'manage',
    },
  },
  {
    path: '/assignment-partners/:id/show',
    name: 'assignment-partners-show',
    component: () => import('@/views/pages/assignment/partner/Edit.vue'),
    meta: {
      name: 'Assign Partner',
      navActiveLink: 'assignment-partners',
      resource: 'Komtim - Assignment',
      action: 'manage',
    },
  },
]
