const pageName = { singular: 'Kantor Operasional', plural: 'Kantor Operasional' }

export default [
  {
    path: '/operational-office',
    name: 'operational-office',
    component: () => import('@/views/pages/master-data/operational-office/List.vue'),
    meta: {
      name: 'Kantor Operasional',
      routeCreate: 'operational-office-create',
      routeEdit: 'operational-office-edit',
      resource: 'Management',
      action: 'manage',
    },
  },
  {
    path: '/operational-office/create',
    name: 'operational-office-create',
    component: () => import('@/views/pages/master-data/operational-office/Add.vue'),
    meta: {
      name: 'Kantor Operasional',
      navActiveLink: 'operational-office',
      resource: 'Management',
      action: 'manage',
    },
  },
  {
    path: '/operational-office/:id/edit',
    name: 'operational-office-edit',
    component: () => import('@/views/pages/master-data/operational-office/Edit.vue'),
    meta: {
      name: 'Kantor Operasional',
      navActiveLink: 'operational-office',
      resource: 'Management',
      action: 'manage',
    },
  },
]
