export default [
  {
    path: '/admin-dashboard-komship',
    name: 'cod-dashboard',
    component: () => import('@/views/pages/komship/dashboard/Index.vue'),
    meta: {
      resource: 'Komship',
      action: 'manage',
    },
  },
  {
    path: '/admin-dashboard-komship/user-growth-performance',
    name: 'user-growth-performance',
    component: () => import(
      '@/views/pages/komship/dashboard/components/user-growth-performance/Index.vue'
    ),
    meta: {
      resource: 'Komship',
      action: 'manage',
    },
  },
  {
    path: '/admin-dashboard-kompack',
    name: 'admin-dashboard-kompack',
    component: () => import('@/views/pages/kompack/dashboard/Index.vue'),
    meta: {
      resource: 'Kompack',
      action: 'manage',
    },
  },
  {
    path: '/v11/admin-dashboard-komship',
    name: 'dashboard-admin-v11',
    component: () => import('@/views/pages/dashboard-admin-1.1'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
    },
  },
  {
    path: '/admin-dashboard-komtim',
    name: 'admin-dashboard-komtim',
    component: () => import('@/views/pages/komtim/dashboard/Index'),
    meta: {
      resource: 'Komtim - Dashboard',
      action: 'manage',
    },
  },
  {
    path: '/dashboard',
    name: 'partner-home',
    component: () => import('@/views/pages/dashboard/PartnerHome'),
    meta: {
      resource: 'PartnerHome',
      action: 'read',
    },
  },
  {
    path: '/home',
    name: 'talent-home',
    component: () => import('@/views/pages/dashboard/Home'),
    meta: {
      resource: 'TalentHome',
      action: 'read',
    },
  },
  {
    path: '/talent/profile',
    name: 'talent-profile',
    component: () => import('@/views/pages/talent/talent/Profile.vue'),
    meta: {
      resource: 'TalentProfile',
      action: 'manage',
    },
  },
]
