import { axiosKomship } from '@/store/helpers'

export default {
  namespaced: true,
  state: () => ({
    slaItems: [],
  }),
  mutations: {
    UpdateSlaItems(state, response) {
      state.slaItems = response.data.data
    },
  },
  actions: {
    FetchData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .get(
            `/v1/admin/sla/monitoring?start_date=${payload.startDate}&end_date=${payload.endDate}`,
          )
          .then(response => {
            resolve(response)
            commit('UpdateSlaItems', response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    ExportData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosKomship().get(`/v1/sla/export?start_date=${payload.startDate}&end_date=${payload.endDate}&shipping=${payload.shipping}&type=${payload.withHoldDuration}`).then(response => {
          resolve(response)
        }).catch(err => {
          console.log('export err', err.response)
          reject(err)
        })
      })
    },
  },
}
