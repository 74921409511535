export default [
  {
    path: '/kuisioner',
    name: 'kuisioner',
    component: () => import('@/views/pages/komtim/talent/kuisioner/Index.vue'),
    meta: {
      name: 'Skill',
      resource: 'Komtim - Talent',
      action: 'manage',
    },
  },
]
