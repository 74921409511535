const pageName = { singular: 'Device', plural: 'Devices' }

export default [
  {
    path: '/device',
    name: 'device',
    component: () => import('@/views/pages/komtim/talent/device/List.vue'),
    meta: {
      name: 'Device',
      routeCreate: 'device-create',
      routeEdit: 'device-edit',
      resource: 'Komtim - Device',
      action: 'manage',
    },
  },
  {
    path: '/device/create',
    name: 'device-create',
    component: () => import('@/views/pages/komtim/talent/device/Add.vue'),
    meta: {
      name: 'Device',
      navActiveLink: 'device',
      resource: 'Komtim - Device',
      action: 'manage',
    },
  },
  {
    path: '/device/:id/edit',
    name: 'device-edit',
    component: () => import('@/views/pages/komtim/talent/device/Edit.vue'),
    meta: {
      name: 'Device',
      navActiveLink: 'device',
      resource: 'Komtim - Device',
      action: 'manage',
    },
  },
]
