export default [
  {
    path: '/claim-retur',
    name: 'claim-retur',
    component: () => import('@/views/pages/claim-retur/List.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'claim-retur',
      action: 'manage',
    },
  },
]
