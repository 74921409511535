export default [
  {
    path: '/invoices-admin',
    name: 'invoices-admin',
    component: () => import('@/views/pages/komtim/invoices/invoice-admin/List.vue'),
    meta: {
      name: 'Invoices',
      routeCreate: 'invoices-admin-create',
      routeEdit: 'invoices-admin-edit',
      routeDisbursement: 'invoices-admin-disbursement',
      routeMonitor: 'invoices-admin-disbursement-monitor',
      routeDetailHistory: 'invoices-detail-history',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
  {
    path: '/invoices-admin/create',
    name: 'invoices-admin-create',
    component: () => import('@/views/pages/komtim/invoices/invoice-admin/Add.vue'),
    meta: {
      name: 'Invoices',
      navActiveLink: 'invoices-admin',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
  {
    path: '/invoices-admin/:id/edit',
    name: 'invoices-admin-edit',
    component: () => import('@/views/pages/komtim/invoices/invoice-admin/Edit.vue'),
    meta: {
      name: 'Invoices',
      navActiveLink: 'invoices-admin',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
  {
    path: '/invoices-admin/:id/disbursement',
    name: 'invoices-admin-disbursement',
    component: () => import('@/views/pages/komtim/invoices/invoice-admin/Disbursement.vue'),
    meta: {
      name: 'Invoices',
      navActiveLink: 'invoices-admin',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
  {
    path: '/invoices-admin/:id/disbursement/monitor',
    name: 'invoices-admin-disbursement-monitor',
    component: () => import('@/views/pages/komtim/invoices/invoice-admin/Monitor.vue'),
    meta: {
      name: 'Invoices',
      navActiveLink: 'invoices-admin',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
  {
    path: '/invoices-admin/:id/detail-history',
    name: 'invoices-detail-history',
    component: () => import('@/views/pages/komtim/invoices/invoice-admin/DetailHistory.vue'),
    meta: {
      name: 'Invoices',
      navActiveLink: 'invoices-admin',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
]
