import { komtimAxiosIns } from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    newRegister: 0,
  },
  getters: {
    newRegister: state => state.newRegister,
  },
  mutations: {
    UPDATE_COUNT(state, data) {
      state.newRegister = data
    },
  },
  actions: {
    getTotal({ commit }) {
      const url = 'v1/talent_pool/user/count_follow_up'
      komtimAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          const { count } = data
          commit('UPDATE_COUNT', count)
        })
        .catch(() => {
        // eslint-disable-next-line no-underscore-dangle
          this._vm.$toast_error({ message: 'Get count new register failed' })
        })
    },
  },
}
