export default [
  {
    path: '/',
    name: 'homepage',
    component: () => import('@/views/pages/homepage/Index'),
    meta: {
      resource: 'Dashboard',
    },
  },
]
