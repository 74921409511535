export default [
  {
    path: '/information',
    name: 'information',
    component: () => import('@/views/pages/partner/information/List.vue'),
    meta: {
      name: 'Information',
      resource: 'Partner',
      action: 'manage',
    },
  },
  {
    path: '/information/details/:id',
    name: 'partner-details',
    component: () => import('@/views/pages/partner/information/Details.vue'),
    meta: {
      name: 'Partner Details',
      navActiveLink: 'information',
      resource: 'Partner',
      action: 'manage',
    },
  },
]
