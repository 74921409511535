const pageName = { singular: 'Kurikulum', plural: 'Kurikulum' }

export default [
  {
    path: '/curriculum',
    name: 'curriculum',
    component: () => import('@/views/pages/komtim/training/curriculum/List.vue'),
    meta: {
      name: 'Kurikulum',
      routeCreate: 'curriculum-create',
      routeEdit: 'curriculum-edit',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
  {
    path: '/curriculum/create',
    name: 'curriculum-create',
    component: () => import('@/views/pages/komtim/training/curriculum/Add.vue'),
    meta: {
      name: 'Kurikulum',
      navActiveLink: 'curriculum',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
  {
    path: '/curriculum/:id/edit',
    name: 'curriculum-edit',
    component: () => import('@/views/pages/komtim/training/curriculum/Edit.vue'),
    meta: {
      name: 'Kurikulum',
      navActiveLink: 'curriculum',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
]
