export default [
  {
    path: '/monitoring-pickup',
    name: 'monitoring-pickup',
    component: () => import('@/views/pages/komship/monitoring-pickup/List.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'monitoring-pickup',
      action: 'read',
    },
  },
]
