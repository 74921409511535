import Vue from 'vue'

import axios from 'axios'
import useJwt from '@core/auth/jwt/useJwt'

const { jwt } = useJwt(axios, {})
const token = jwt.getToken()

export const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 12000,
  headers: {
    'Application-Name': 'Web Komerce',
  },
})

export const komshipAxiosIns = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_BASE_URL_KOMSHIP,
  // timeout: 12000,
})
export const newAxiosIns = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_BASE_URL_NEW,
  // timeout: 12000,
})

export const affiliateAxiosIns = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_BASE_URL_AFFILIATE,
})

export const komtimAxiosIns = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_BASE_URL_KOMTIM,
})

export const collaboratorAxiosIns = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_BASE_URL_KOLABORATOR,
})
export const openApiAxiosIns = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_BASE_URL_OPEN_API,
})

export const komfromAxiosIns = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Headers': 'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version',
    'Access-Control-Allow-Methods': 'GET,DELETE,PATCH,POST,PUT',
    'Content-Type': 'application/json',
  },
  baseURL: process.env.VUE_APP_BASE_URL_KOMFORM,
})

axiosIns.interceptors.response.use(
  response => {
    if (response.data.code && response.data.code === 403) {
      window.location = '/unauthenticated'
    }
    return response
  },
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    return Promise.reject(error)
  },
)

komshipAxiosIns.interceptors.response.use(
  response => {
    if (response.data.code && response.data.code === 403) {
      window.location = '/unauthenticated'
    }
    return response
  },
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    return Promise.reject(error)
  },
)

affiliateAxiosIns.interceptors.response.use(
  response => {
    if (response.data.code && response.data.code === 403) {
      window.location = '/unauthenticated'
    }
    return response
  },
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    return Promise.reject(error)
  },
)

komtimAxiosIns.interceptors.response.use(
  response => {
    if (response.data.code && response.data.code === 403) {
      window.location = '/unauthenticated'
    }
    return response
  },
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    return Promise.reject(error)
  },
)

newAxiosIns.interceptors.response.use(
  response => {
    if (response.data.code && response.data.code === 403) {
      window.location = '/unauthenticated'
    }
    return response
  },
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    return Promise.reject(error)
  },
)

komfromAxiosIns.interceptors.response.use(
  response => {
    if (response.data.code && response.data.code === 403) {
      window.location = '/unauthenticated'
    }
    return response
  },
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    return Promise.reject(error)
  },
)

collaboratorAxiosIns.interceptors.response.use(
  response => {
    if (response.data.code && response.data.code === 403) {
      window.location = '/unauthenticated'
    }
    return response
  },
  error => {
    if (error?.response?.status === 403) {
      window.location = '/unauthenticated'
    }
    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns
Vue.prototype.$http_komship = komshipAxiosIns
Vue.prototype.$http_new = newAxiosIns
Vue.prototype.$http_collaborator = collaboratorAxiosIns
Vue.prototype.$http_komform = komfromAxiosIns

export default axiosIns
