const pageName = { singular: 'Sektor Bisnis', plural: 'Sektor Bisnis' }

export default [
  {
    path: '/business-category',
    name: 'business-category',
    component: () => import('@/views/pages/partner/business-category/Index.vue'),
    meta: {
      name: 'Sektor Bisnis',
      resource: 'Partner',
      action: 'manage',
    },
  },
]
