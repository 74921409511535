export default [
  {
    path: '/openapi/dashboard',
    name: 'dashboard-open-api',
    component: () => import('@/views/pages/open-api/index.vue'),
    meta: {
      resource: 'Open Api',
      navActiveLink: 'open-api',
      action: 'manage',
    },
  },
  {
    path: '/openapi/collaborator/detail/:id',
    name: 'detail-open-api',
    component: () => import('@/views/pages/open-api/detail.vue'),
    meta: {
      resource: 'Open Api',
      navActiveLink: 'open-api',
      action: 'manage',
    },
  },
]
