import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

// maps
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    libraries: 'places',
  },
})
