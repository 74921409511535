export default [
  {
    path: '/metrics-realiability',
    name: 'metrics-realiability',
    component: () => import('@/views/pages/metrics-realiability/List.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'metrics-realiability',
      action: 'manage',
    },
  },
]
