const pageName = { singular: 'Divisi', plural: 'Divisi' }

export default [
  {
    path: '/churn-partner',
    name: 'churn-partner',
    component: () => import('@/views/pages/komtim/churn-partner/List.vue'),
    meta: {
      name: 'Churn Partner',
      resource: 'Komtim - Churn Partner',
      action: 'manage',
      routeDetail: 'churn-partner-detail',
    },
  },
  {
    path: '/churn-partner/detail/:id',
    name: 'churn-partner-detail',
    component: () => import('@/views/pages/komtim/churn-partner/Detail.vue'),
    meta: {
      resource: 'Komtim - Churn Partner',
      action: 'manage',
    },
  },
]
