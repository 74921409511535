const pageName = { singular: 'Staff', plural: 'Staff' }

export default [
  {
    path: '/staff',
    name: 'staff',
    component: () => import('@/views/pages/management/staff/List.vue'),
    meta: {
      name: 'Staff',
      routeCreate: 'staff-create',
      routeEdit: 'staff-edit',
      resource: 'Management',
      action: 'manage',
    },
  },
  {
    path: '/staff/create',
    name: 'staff-create',
    component: () => import('@/views/pages/management/staff/Add.vue'),
    meta: {
      name: 'Staff',
      navActiveLink: 'staff',
      resource: 'Management',
      action: 'manage',
    },
  },
  {
    path: '/staff/:id/edit',
    name: 'staff-edit',
    component: () => import('@/views/pages/management/staff/Edit.vue'),
    meta: {
      name: 'Staff',
      navActiveLink: 'staff',
      resource: 'Management',
      action: 'manage',
    },
  },
]
