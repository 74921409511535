export default [
  {
    path: '/metrics-financial',
    name: 'metrics-financial',
    component: () => import('@/views/pages/metrics-financial/List.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'metrics-financial',
      action: 'manage',
    },
  },
]
