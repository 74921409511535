export default [
  {
    path: '/admin-fees',
    name: 'admin-fees',
    component: () => import('@/views/pages/master-data/admin-fee/List.vue'),
    meta: {
      name: 'Biaya Admin',
      routeCreate: 'admin-fees-create',
      routeEdit: 'admin-fees-edit',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
  {
    path: '/admin-fees/create',
    name: 'admin-fees-create',
    component: () => import('@/views/pages/master-data/admin-fee/Add.vue'),
    meta: {
      name: 'Biaya Admin',
      navActiveLink: 'admin-fees',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
  {
    path: '/admin-fees/:id/edit',
    name: 'admin-fees-edit',
    component: () => import('@/views/pages/master-data/admin-fee/Edit.vue'),
    meta: {
      name: 'Biaya Admin',
      navActiveLink: 'admin-fees',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
]
