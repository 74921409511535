const pageName = { singular: 'Talent', plural: 'Daftar Talent' }

export default [
  {
    path: '/talent',
    name: 'talent',
    component: () => import('@/views/pages/komtim/talent/list-talent/Index.vue'),
    meta: {
      name: 'Daftar talent',
      routeCreate: 'talent-create',
      routeEdit: 'talent-edit',
      routeEditResign: 'talent-resign-edit',
      routeResign: 'talent-resign',
      resource: 'Komtim - Talent',
      action: 'manage',
    },
  },
  {
    path: '/talent/create',
    name: 'talent-create',
    component: () => import('@/views/pages/komtim/talent/list-talent/Add.vue'),
    meta: {
      name: 'Daftar talent',
      navActiveLink: 'talent',
      resource: 'Komtim - Talent',
      action: 'manage',
    },
  },
  {
    path: '/talent/:id/experience/create',
    name: 'talent-experience-create',
    component: () => import('@/views/pages/komtim/talent/list-talent/experience/Add.vue'),
    meta: {
      name: 'Daftar talent',
      navActiveLink: 'talent',
      resource: 'Komtim - Talent',
      action: 'manage',
    },
  },
  {
    path: '/talent/:id/experience/edit',
    name: 'talent-experience-create',
    component: () => import('@/views/pages/komtim/talent/list-talent/experience/Edit.vue'),
    meta: {
      name: 'Daftar talent',
      navActiveLink: 'talent',
      resource: 'Komtim - Talent',
      action: 'manage',
    },
  },
  {
    path: '/talent/:id/edit',
    name: 'talent-edit',
    component: () => import('@/views/pages/komtim/talent/list-talent/Edit.vue'),
    meta: {
      name: 'Daftar talent',
      navActiveLink: 'talent',
      resource: 'Komtim - Talent',
      action: 'manage',
    },
  },
  {
    path: '/talent/resign/:id/edit',
    name: 'talent-resign-edit',
    component: () => import('@/views/pages/talent/talent/EditResign.vue'),
    meta: {
      name: pageName,
      navActiveLink: 'talent',
      resource: 'Talent',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Talent',
        },
        {
          text: pageName.plural,
          route: 'talent',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/talent/resign/:id',
    name: 'talent-resign',
    component: () => import('@/views/pages/komtim/talent/list-talent/Resign.vue'),
    meta: {
      name: 'talent-resign',
      navActiveLink: 'talent',
      resource: 'Komtim - Talent',
      action: 'manage',
    },
  },
]
