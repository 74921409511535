export default [
  {
    path: '/retur-insight',
    name: 'retur-insight',
    component: () => import('@/views/pages/dashboard-admin-1.1/analitik/ReturInsight.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'retur-insight',
      action: 'manage',
    },
  },
  {
    path: '/analitics-returnarea',
    name: 'retur-area',
    component: () => import('@/views/pages/dashboard-admin-1.1/analitik/ReturArea.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'retur-area',
      action: 'manage',
    },
  },
]
