export default [
  {
    path: '/dashboard-affiliate',
    name: 'dashboard-affiliate',
    component: () => import('@/views/pages/affiliate/dashboard/list/Index.vue'),
    meta: {
      resource: 'Affiliate',
      navActiveLink: 'dashboard-affiliate',
      action: 'read',
    },
  },
  {
    path: '/dashboard-affiliate/:id',
    name: 'dashboard-affiliate',
    component: () => import('@/views/pages/affiliate/dashboard/detail/Index.vue'),
    meta: {
      resource: 'Affiliate',
      navActiveLink: 'dashboard-affiliate',
      action: 'read',
    },
  },
  {
    path: '/affiliate/leaderboard',
    name: 'affiliate-leaderboard',
    component: () => import('@/views/pages/affiliate/leaderboard/leaderboard.vue'),
    meta: {
      resource: 'Affiliate',
      navActiveLink: 'affiliate-leaderboard',
      action: 'manage',
    },
  },
]
