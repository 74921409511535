export default [
  {
    path: '/monitoring-order',
    name: 'monitoring-order',
    component: () => import('@/views/pages/komship/monitoring-order/MonitoringOrder.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'monitoring-order',
      action: 'manage',
    },
  },
]
