import { axiosIns } from '@/libs/axios'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    listMenu: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    UPDATE_MENU_ADMIN(state, data) {
      state.listMenu = data
    },
  },
  actions: {
    async init({ dispatch }) {
      dispatch('getMenuAdmin')
    },
    async getMenuAdmin({ commit }) {
      try {
        const response = await axiosIns.get('/v1/user/permission')
        commit('UPDATE_MENU_ADMIN', response.data.data)
      } catch (e) {
        console.error(e)
      }
    },
  },
}
