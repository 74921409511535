export default [
  {
    path: '/kompack-data-mitra-gudang',
    name: 'kompack-data-mitra-gudang',
    component: () => import('@/views/pages/kompack/mitragudang/List'),
    meta: {
      resource: 'Kompack',
      action: 'manage',
    },
  },
  {
    path: '/kompack-tambah-list-data-mitra-gudang',
    name: 'kompack-tambah-list-data-mitra-gudang',
    component: () => import('@/views/pages/kompack/mitragudang/tambahlistdatamitragudang'),
    meta: {
      navActiveLink: 'kompack-data-mitra-gudang',
      resource: 'Kompack',
      action: 'manage',
    },
  },
  {
    path: '/kompack-rincian-mitra-gudang/:id',
    name: 'kompack-rincian-mitra-gudang',
    component: () => import('@/views/pages/kompack/mitragudang/rincianmitragudang'),
    meta: {
      navActiveLink: 'kompack-data-mitra-gudang',
      resource: 'Kompack',
      action: 'manage',
    },
  },
  {
    path: '/kompack-rincian-mitra-gudang/:id/information',
    name: 'kompack-rincian-mitra-gudang-information',
    component: () => import('@/views/pages/kompack/mitragudang/rincianinfomitragudang'),
    meta: {
      navActiveLink: 'kompack-data-mitra-gudang',
      resource: 'Kompack',
      action: 'manage',
    },
  },
]
