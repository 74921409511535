const pageName = { singular: 'Training Center', plural: 'Training Centers' }

export default [
  {
    path: '/training-center',
    name: 'training-center',
    component: () => import('@/views/pages/komtim/training/training-center/List.vue'),
    meta: {
      name: 'Training Center',
      routeCreate: 'training-center-create',
      routeEdit: 'training-center-edit',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
  {
    path: '/training-center/create',
    name: 'training-center-create',
    component: () => import('@/views/pages/komtim/training/training-center/Add.vue'),
    meta: {
      name: 'Training Center',
      navActiveLink: 'training-center',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
  {
    path: '/training-center/:id/edit',
    name: 'training-center-edit',
    component: () => import('@/views/pages/komtim/training/training-center/Edit.vue'),
    meta: {
      name: 'Training Center',
      navActiveLink: 'training-center',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
]
