import { axiosKomshipNew } from '@/store/helpers'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    forceDelivered({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosKomshipNew()
          .post(`/komship/api/v1/admin/order/${payload.orderId}/force-delivered`, payload.data)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },
  },
}
