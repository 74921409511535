const pageName = { singular: 'Training Program', plural: 'Training Programs' }

export default [
  {
    path: '/training-program',
    name: 'training-program',
    component: () => import('@/views/pages/komtim/training/training-program/List.vue'),
    meta: {
      name: 'Training Program',
      routeCreate: 'training-program-create',
      routeEdit: 'training-program-edit',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
  {
    path: '/training-program/create',
    name: 'training-program-create',
    component: () => import('@/views/pages/komtim/training/training-program/Add.vue'),
    meta: {
      name: 'Training Program',
      navActiveLink: 'training-program',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
  {
    path: '/training-program/:id/edit',
    name: 'training-program-edit',
    component: () => import('@/views/pages/komtim/training/training-program/Edit.vue'),
    meta: {
      name: 'Training Program',
      navActiveLink: 'training-program',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
  {
    path: '/training-program/:id/detail',
    name: 'training-program-detail',
    component: () => import('@/views/pages/komtim/training/training-program/Detail.vue'),
    meta: {
      name: 'Training Program',
      navActiveLink: 'training-program',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
]
