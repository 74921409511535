export default [
  {
    path: '/sla-monitoring',
    name: 'sla-monitoring',
    component: () => import('@/views/pages/komship/sla-monitoring/SLA-Monitoring.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'sla-monitoring',
      action: 'manage',
    },
  },
]
