const pageName = { singular: 'Trainer', plural: 'Trainer' }

export default [
  {
    path: '/instructor',
    name: 'instructor',
    component: () => import('@/views/pages/komtim/training/trainer/List.vue'),
    meta: {
      name: 'Trainer',
      routeCreate: 'instructor-create',
      routeEdit: 'instructor-edit',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
  {
    path: '/instructor/create',
    name: 'instructor-create',
    component: () => import('@/views/pages/komtim/training/trainer/Add.vue'),
    meta: {
      name: 'Trainer',
      navActiveLink: 'instructor',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
  {
    path: '/instructor/:id/edit',
    name: 'instructor-edit',
    component: () => import('@/views/pages/komtim/training/trainer/Edit.vue'),
    meta: {
      name: 'Trainer',
      navActiveLink: 'instructor',
      resource: 'Komtim - Training',
      action: 'manage',
    },
  },
]
