export default [
  {
    path: '/evaluation',
    name: 'evaluation',
    component: () => import('@/views/pages/komtim/partner/evaluation/List.vue'),
    meta: {
      name: 'Evaluasi',
      resource: 'Komtim - Evaluasi Partner',
      action: 'manage',
    },
  },
]
