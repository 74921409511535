const pageName = { singular: 'Working Space', plural: 'Working Space' }

export default [
  {
    path: '/work-space',
    name: 'work-space',
    component: () => import('@/views/pages/master-data/working-space/List.vue'),
    meta: {
      name: 'Working Space',
      routeCreate: 'work-space-create',
      routeEdit: 'work-space-edit',
      resource: 'Management',
      action: 'manage',
    },
  },
  {
    path: '/work-space/create',
    name: 'work-space-create',
    component: () => import('@/views/pages/master-data/working-space/Add.vue'),
    meta: {
      name: 'Working Space',
      navActiveLink: 'work-space',
      resource: 'Management',
      action: 'manage',
    },
  },
  {
    path: '/work-space/:id/edit',
    name: 'work-space-edit',
    component: () => import('@/views/pages/master-data/working-space/Edit.vue'),
    meta: {
      name: 'Working Space',
      navActiveLink: 'work-space',
      resource: 'Management',
      action: 'manage',
    },
  },
]
