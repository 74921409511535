const pageName = { singular: 'Talent', plural: 'Daftar Talent' }

export default [
  {
    path: '/report-performance',
    name: 'report-performance',
    component: () => import('@/views/pages/komtim/talent/report-performance/Index.vue'),
    meta: {
      name: 'Report Performance',
      resource: 'Komtim - Talent',
      action: 'manage',
    },
  },
]
