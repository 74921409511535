export default [
  {
    path: '/search-order',
    name: 'search-order',
    component: () => import('@/views/pages/komship/search-order/List.vue'),
    meta: {
      resource: 'Komship',
      navActiveLink: 'search-order',
      action: 'manage',
    },
  },
]
