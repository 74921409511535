export default [
  {
    path: '/komads/whitelist-dashboard',
    name: 'komads-whitelist-dashboard',
    component: () => import('@/views/pages/komads/whitelist-dashboard/List.vue'),
    meta: {
      resource: 'Komads',
      action: 'manage',
    },
  },
]
