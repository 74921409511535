export default [
  {
    path: '/tiket',
    name: 'tiket',
    component: () => import('@/views/pages/tiket/Tiket.vue'),
    meta: {
      resource: 'Komship',
      toDetail: 'tiket-detail',
      action: 'manage',
    },
  },
  {
    path: '/tiket/detail/:id',
    name: 'tiket-detail',
    component: () => import('@/views/pages/tiket/DetailTiket.vue'),
    meta: {
      name: 'TiketDetail',
      resource: 'Komship',
      action: 'manage',
    },
  },
  {
    path: '/tiket/detail/order/:id',
    name: 'tiket-detail-order',
    component: () => import('@/views/pages/tiket/DetailOrder.vue'),
    meta: {
      name: 'TiketDetail',
      resource: 'Komship',
      action: 'manage',
    },
  },
  {
    path: '/tiket/kolaborator/detail/:id',
    name: 'tiket-detail-kolaborator',
    component: () => import('@/views/pages/tiket/detail-ticket-collaborator/detail-ticket-collaborator.vue'),
    meta: {
      name: 'TiketDetailKolaborator',
      resource: 'Komship',
      action: 'manage',
    },
  },
  {
    path: '/tiket/kolaborator/detail/order/:id',
    name: 'tiket-detail-order-kolaborator',
    component: () => import('@/views/pages/tiket/detail-ticket-collaborator/detail-order-collaborator.vue'),
    meta: {
      name: 'TiketDetailOrderKolaborator',
      resource: 'Komship',
      action: 'manage',
    },
  },
  {
    path: '/performa-mitra',
    name: 'performa-mitra',
    component: () => import('@/views/pages/tiket/performance-mitra'),
    meta: {
      name: 'PerformaMitra',
      resource: 'Komship',
      action: 'manage',
    },
  },
  {
    path: '/return-monitor',
    name: 'package-return',
    component: () => import('@/views/pages/tiket/package-return/List.vue'),
    meta: {
      name: 'PackageReturn',
      resource: 'Komship',
      action: 'manage',
    },
  },
]
