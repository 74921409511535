export default [
  {
    path: '/role-access',
    name: 'role-access',
    component: () => import('@/views/pages/management-admin/role-access/List.vue'),
    meta: {
      name: 'Role Access',
      routeCreate: 'role-access-create',
      routeEdit: 'role-access-edit',
      resource: 'Kelola User',
      action: 'manage',
    },
  },
  {
    path: '/role-access/create',
    name: 'role-access-create',
    component: () => import('@/views/pages/management-admin/role-access/Add.vue'),
    meta: {
      name: 'Role Access',
      navActiveLink: 'role-access',
      resource: 'Kelola User',
      action: 'manage',
    },
  },
  {
    path: '/role-access/:id/edit',
    name: 'role-access-edit',
    component: () => import('@/views/pages/management-admin/role-access/Edit.vue'),
    meta: {
      name: 'Role Access',
      navActiveLink: 'role-access',
      resource: 'Kelola User',
      action: 'manage',
    },
  },
]
