import operationalOffice from './operational-office'
import office from './working-space'
import division from './division'
import position from './position'
import adminFee from './admin-fee'
import sharingFee from './sharing-fee'

export default [
  ...operationalOffice,
  ...office,
  ...position,
  ...division,
  ...adminFee,
  ...sharingFee,
]
