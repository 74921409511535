export default [
  {
    path: '/partner-komchat',
    name: 'partner-komchat',
    component: () => import('@/views/pages/komchat/index.vue'),
    meta: {
      resource: 'Komchat',
      navActiveLink: 'Komchat',
      action: 'manage',
    },
  },

]
