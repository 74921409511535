const pageName = { singular: 'Talent', plural: 'Daftar Talent' }

export default [
  {
    path: '/request-edit',
    name: 'request-edit',
    component: () => import('@/views/pages/komtim/talent/request-edit/Index.vue'),
    meta: {
      name: 'Daftar talent',
      routeDetail: 'detail-request',
      routeApprovement: 'approvement-request',
      resource: 'Komtim - Talent',
      action: 'manage',
    },
  },
  {
    path: '/request-edit/:id',
    name: 'detail-request',
    component: () => import('@/views/pages/komtim/talent/request-edit/Detail.vue'),
    meta: {
      name: 'Daftar talent',
      routeApprovement: 'approvement-request',
      resource: 'Komtim - Talent',
      action: 'manage',
    },
  },
  {
    path: '/request-edit/:uuid/approvement',
    name: 'approvement-request',
    component: () => import('@/views/pages/komtim/talent/request-edit/Approvement.vue'),
    meta: {
      name: 'Daftar talent',
      resource: 'Komtim - Talent',
      action: 'manage',
    },
  },
  // {
  //   path: '/talent/:id/experience/create',
  //   name: 'talent-experience-create',
  //   component: () => import('@/views/pages/komtim/talent/list-talent/experience/Add.vue'),
  //   meta: {
  //     name: 'Daftar talent',
  //     navActiveLink: 'talent',
  //     resource: 'Komtim - Talent',
  //     action: 'manage',
  //   },
  // },
  // {
  //   path: '/talent/:id/experience/edit',
  //   name: 'talent-experience-create',
  //   component: () => import('@/views/pages/komtim/talent/list-talent/experience/Edit.vue'),
  //   meta: {
  //     name: 'Daftar talent',
  //     navActiveLink: 'talent',
  //     resource: 'Komtim - Talent',
  //     action: 'manage',
  //   },
  // },
]
