export default [
  {
    path: '/komcards-dashboard',
    name: 'komcards-dashboard',
    component: () => import('@/views/pages/komcards/dashboard/Index.vue'),
    meta: {
      resource: 'Komcards',
      navActiveLink: 'komcards-dashboard',
      action: 'read',
    },
  },
  {
    path: '/komcards-data-partner',
    name: 'komcards-data-partner',
    component: () => import('@/views/pages/komcards/data-partner/List.vue'),
    meta: {
      resource: 'Komcards',
      navActiveLink: 'komcards-data-partner',
      action: 'read',
    },
  },
  {
    path: '/komcards-data-partner/:id',
    name: 'komcards-data-partner-detail',
    component: () => import('@/views/pages/komcards/data-partner/Detail/Detail.vue'),
    meta: {
      resource: 'Komcards',
      navActiveLink: 'komcards-data-partner',
      action: 'read',
    },
  },
  {
    path: '/komcards-data-partner/mutation/:id',
    name: 'komcards-mutation',
    component: () => import('@/views/pages/komcards/data-partner/Mutation/List.vue'),
    meta: {
      resource: 'Komcards',
      navActiveLink: 'komcards-data-partner',
      action: 'read',
    },
  },
  {
    path: '/komcards-data-partner/sync-transaction/:id',
    name: 'komcards-mutation-sync',
    component: () => import('@/views/pages/komcards/data-partner/Sync-transaction/List.vue'),
    meta: {
      resource: 'Komcards',
      navActiveLink: 'komcards-data-partner',
      action: 'read',
    },
  },
  {
    path: '/komcards-data-partner/insufficient-balance/:id',
    name: 'insufficient-balance',
    component: () => import('@/views/pages/komcards/data-partner/insufficient-balance/List.vue'),
    meta: {
      resource: 'Komcards',
      navActiveLink: 'komcards-data-partner',
      action: 'read',
    },
  },
]
