export default [
  {
    path: '/fulfillment-service',
    name: 'fulfillment-service',
    component: () => import('@/views/pages/fullfilment/List.vue'),
    meta: {
      resource: 'Kompack',
      action: 'manage',
    },
  },
  {
    path: '/fulfillment-service/:id',
    name: 'fulfillment-service-detail',
    component: () => import('@/views/pages/fullfilment/Detail.vue'),
    meta: {
      navActiveLink: 'fulfillment-service',
      resource: 'Kompack',
      action: 'manage',
    },
  },
  {
    path: '/fulfillment-service/:id/:id',
    name: 'fulfillment-service-detail-product',
    component: () => import('@/views/pages/fullfilment/DetailProduct.vue'),
    meta: {
      navActiveLink: 'fulfillment-service',
      resource: 'Kompack',
      action: 'manage',
    },
  },
  {
    path: '/history-submission',
    name: 'history-submission',
    component: () => import('@/views/pages/submisson-history/List.vue'),
    meta: {
      navActiveLink: 'history-submission',
      resource: 'Kompack',
      action: 'manage',
    },
  },
  {
    path: '/history-submission/:id',
    name: 'history-submission-detail',
    component: () => import('@/views/pages/submisson-history/Detail.vue'),
    meta: {
      navActiveLink: 'history-submission',
      resource: 'Kompack',
      action: 'manage',
    },
  },
]
