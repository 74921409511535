export default [
  // Withdrawal Partner
  {
    path: '/pencairan',
    name: 'cod-pencairan',
    component: () => import('@/views/pages/pencairan/List'),
    meta: {
      resource: 'Pencairan',
      action: 'manage',
    },
  },
  {
    path: '/pencairan/:slug/upload-bukti-transfer',
    name: 'cod-penarikan-upload-bukti-transfer',
    component: () => import('../../views/pages/pencairan/UploadBuktiTransfer'),
    meta: {
      navActiveLink: 'cod-pencairan',
      resource: 'Pencairan',
      action: 'manage',
    },
  },
  {
    path: '/pencairan/:slug',
    name: 'cod-rincian-penarikan-saldo',
    component: () => import('@/views/pages/pencairan/WithdrawalDetails.vue'),
    meta: {
      navActiveLink: 'cod-pencairan',
      resource: 'Pencairan',
      action: 'manage',
    },
  },
  // withdrawal mitra
  {
    path: '/withdrawal-mitra',
    name: 'withdrawal-mitra',
    component: () => import('@/views/pages/withdrawal/mitra/List.vue'),
    meta: {
      resource: 'Pencairan',
      navActiveLink: 'withdrawal-mitra',
      action: 'manage',
    },
  },
  {
    path: '/withdrawal-mitra/:id',
    name: 'withdrawal-mitra',
    component: () => import('@/views/pages/withdrawal/mitra/Detail.vue'),
    meta: {
      resource: 'Pencairan',
      navActiveLink: 'withdrawal-mitra',
      action: 'manage',
    },
  },
  {
    path: '/withdrawal-mitra/:id/transfer',
    name: 'withdrawal-mitra-manual-transfer',
    component: () => import('@/views/pages/withdrawal/mitra/ManualTransfers.vue'),
    meta: {
      resource: 'Pencairan',
      navActiveLink: 'withdrawal-mitra',
      action: 'manage',
    },
  },

  // withdrawal affiliate
  {
    path: '/affiliate/withdrawal',
    name: 'withdrawal-affiliate',
    component: () => import('@/views/pages/withdrawal/affiliate/List.vue'),
    meta: {
      resource: 'Pencairan',
      navActiveLink: 'withdrawal-affiliate',
      action: 'manage',
    },
  },
  {
    path: '/affiliate/withdrawal/:id',
    name: 'withdrawal-affiliate',
    component: () => import('@/views/pages/withdrawal/affiliate/Detail.vue'),
    meta: {
      resource: 'Pencairan',
      navActiveLink: 'withdrawal-affiliate',
      action: 'manage',
    },
  },
  {
    path: '/affiliate/withdrawal/:id/transfer',
    name: 'withdrawal-affiliate',
    component: () => import('@/views/pages/withdrawal/affiliate/ManualTransfers.vue'),
    meta: {
      resource: 'Pencairan',
      navActiveLink: 'withdrawal-affiliate',
      action: 'manage',
    },
  },
  {
    path: '/withdarawal/collaborator',
    name: 'withdrawal-collaborator',
    component: () => import('@/views/pages/withdrawal/kollaborator/List.vue'),
    meta: {
      resource: 'Pencairan',
      navActiveLink: 'withdrawal-collaborator',
      action: 'manage',
    },
  },
  {
    path: '/withdarawal/collaborator/detail',
    name: 'withdrawal-collaborator-detail',
    component: () => import('@/views/pages/withdrawal/kollaborator/Detail.vue'),
    meta: {
      resource: 'Pencairan',
      navActiveLink: 'withdrawal-collaborator',
      action: 'manage',
    },
  },
  {
    path: '/withdarawal/collaborator/transfer',
    name: 'withdrawal-collaborator-transfer',
    component: () => import('@/views/pages/withdrawal/kollaborator/ManualTransfers.vue'),
    meta: {
      resource: 'Pencairan',
      navActiveLink: 'withdrawal-collaborator',
      action: 'manage',
    },
  },
  {
    path: '/mutation-bank',
    name: 'mutation-bank',
    component: () => import('@/views/pages/withdrawal/mutasi-bank/ListMutation.vue'),
    meta: {
      resource: 'MutationBank',
      navActiveLink: 'mutation-bank',
      action: 'manage',
    },
  },
]
