export default [
  {
    path: '/sharing-fees',
    name: 'sharing-fees',
    component: () => import('@/views/pages/master-data/sharing-fee/List.vue'),
    meta: {
      name: 'Sharing Fee',
      routeCreate: 'sharing-fees-create',
      routeEdit: 'sharing-fees-edit',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
  {
    path: '/sharing-fees/create',
    name: 'sharing-fees-create',
    component: () => import('@/views/pages/master-data/sharing-fee/Add.vue'),
    meta: {
      name: 'Sharing Fee',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
  {
    path: '/sharing-fees/:id/edit',
    name: 'sharing-fees-edit',
    component: () => import('@/views/pages/master-data/sharing-fee/Edit.vue'),
    meta: {
      name: 'Sharing Fee',
      resource: 'Komtim - Keuangan',
      action: 'manage',
    },
  },
]
